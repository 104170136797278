jQuery(document).ready(($) => {
  
  const main = document.querySelector("[role='main']");
  const btns = document.querySelectorAll(".main-nav a");
  const search = document.getElementById("search");
  const searchText = document.getElementById("search-text");
  const gridItems = document.querySelectorAll(".grid [data-type]");

  // Set grid display id and toggle active button.
  function setGridId(id, btn = null) {
    main.id = id;
    $(btns).removeClass("active");
    if (btn) {
      btn.classList.add("active");
    }
  }
  
  // On click of type button, set the selected id.
  function handleTypeButton(e) {
    e.preventDefault();
    let btn = e.currentTarget;
    let id = btn.href.split('#')[1];
    // Remove search query if set.
    searchText.value = "";
    setGridId(id, btn);
  }

  // Attach handler to all type buttons.
  for (let btn of btns) {
    btn.addEventListener("click", (e) => handleTypeButton(e));
  }

  // Handle search submit.
  search.addEventListener("submit", (e) => {
    e.preventDefault();
    // Find out if user has submitted a valid query.
    let q = searchText.value.toLowerCase();
    if (q !== "" || q !== " ") {
      let r = [];
      // Loop each grid item (jersey) and check details
      // text for presence of query string.
      for (let item of gridItems) {
        item.classList.remove("is-result");
        let details = $(item).find(".details").text().toLowerCase();
        if (details.includes(q)) {
          // If found, give the item .is-result class and
          // push to results array for count.
          item.classList.add("is-result");
          r.push(item);
        }
      }
      // We have results, show them.
      if (r.length) {
        setGridId("results");
      }
      // Nothing found, show the no results message.
      else {
        setGridId("no-result");
      }
    }
    // Invalid query, show the no results message.
    else {
      setGridId("no-result");
    }
  });

});